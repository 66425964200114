export const HOME ='/';
export const CHANCE1 ='/chance1';
export const CHANCE2 ='/chance2';
export const CHANCE3 ='/chance3';
export const CHANCE4 ='/chance4';
export const CHANCE2DIF ='/chance2dif';
export const CHANCE3DIF ='/chance3dif';
export const CHANCE4DIF ='/chance4dif';
export const SPLITS ='/splits';
export const BROWSE ='/browse';
export const BROWSECAT ='/browse/:cat';
export const CART ='/cart';
export const GROUPS ='/groups';
export const GROUPSID ='/groups/:id';
export const PROFILE ='/profile';
export const PRODUCT ='/product/:id';
export const ADMIN = '/admin';
export const CHECKOUT = '/checkout';
export const IMAGE ='/image';
export const SEND ='/send';
export const STATIONHOME ='/stationdash';
export const RESETPASSWORD ='/resetpassword/:token';
export const LotteryChecker ='/Lotterychecker';
export const LottoGenerator ='/LottoGenerator';
export const TEST777 ='/777';
export const NEW777 ='/new777';
export const TESTLOTTO ='/lotto';
export const NOT_FOUND ='/*';